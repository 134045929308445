import Cards from "../Card/Card"


const Portfolio = () => {
    
    return (
        <div>
            <Cards />
        </div>
    )
}

export default Portfolio;