import './Contact.scss';
import UpdateForm from "../UpdateForm/UpdateForm";
import LinkedIn from "../../Assets/Logos/icons8-linkedin.svg"
import instagram from "../../Assets/Logos/icons8-instagram-50.png"
import contact from "../../Assets/Logos/icons8-email-50.png"



const Contact = () => {
  return (
    <div className="updatecontainer">
    <div className="contact-container">
      <h2 className="contact-title">We'd Love to Hear from You!</h2>
      <p className='contact-text'>Have questions, feedback, or just want to connect? Reach out to us anytime!</p>
      <ul className="nav">
                <li className="nav__icon"><a href="mailto:info@secondjoke.com"><img src={contact} alt="contact icon"/></a></li>
                <li className="nav__icon"><a href="https://www.instagram.com/secondjokesolutions/"><img src={instagram} alt="Instagram icon"></img></a></li>
                <li className="nav__icon"><a href="https://www.linkedin.com/company/second-joke-solutions/"><img src={LinkedIn} alt="linkedin icon"></img></a></li>
            </ul>
      <p className='contact-text'>Your thoughts and ideas matter to us - let's stay connected!</p>
</div>
    
    
    <UpdateForm />
    </div>
  );
};

export default Contact;
