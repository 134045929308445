import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Main from "./Components/Main/Main";
import About from "./Components/About/About"
import Portfolio from "./Components/Portfolio/Portfolio"
import Blog from "./Components/Blog/Blog"
import Contact from './Components/Contact/Contact';


function App() {
  return (
    <BrowserRouter>
    <div className='pagecontainer'>
    <Header />
    <Routes>
      <Route path="/" element={<Main />}/>
      <Route path= "/about" element={< About />} />
      <Route path='/portfolio' element={<Portfolio />} />
      <Route path='/contact' element={<Contact />} />'
      <Route path='/blog' element={<Blog />} />
    </Routes>
    <Footer />
    </div>
  </BrowserRouter>
  );
}

export default App;
