import "./Main.scss";
import ReactPlayer from "react-player";
import SalvoIntro from '../../Assets/Images/SalvoIntro.mp4';
import UpdateForm from "../UpdateForm/UpdateForm";

const Main = () => {
    
    return (
        <main className="main">
        <section className="hero">
            <ReactPlayer className="video" url={SalvoIntro} playing
        loop
        controls
        muted
        width="100%"
        height="100%"
        title="Salvo Introduction Video"
        aria-label="Salvo Introduction Video: Learn how Salvo makes expense management simple and effective."/>
        </section>
    
        <section className="about">
            <h2 className="about__section">Purpose</h2>
            <p className="about__text">Our goal is to develop meaningful applications that enhance people's time and happiness. We are committed to creating innovative software solutions that make everyday tasks easier and more enjoyable.</p>
            <h2 className="about__section">Passion</h2>
            <p className="about__text">We aim to build a company where creativity, responsibility, and integrity are not just ideals but everyday practices. We strive to make data driven decisions to ensure that our applications are unbiased and effective.</p>
        </section>

        <div className="updatecontainer">
        <UpdateForm />
        </div>
        </main> 
    )
}

export default Main;