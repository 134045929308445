import logobottom from "../../Assets/Logos/White logo - no background.svg"
import "../Footer/Footer.scss"
import LinkedIn from "../../Assets/Logos/icons8-linkedin.svg"
import instagram from "../../Assets/Logos/icons8-instagram-50.png"
import contact from "../../Assets/Logos/icons8-email-50.png"
import { NavLink } from "react-router-dom"


const Footer = () => {
    
    return (
        <nav className="container__footer">
            
            <ul className="nav">
                <li className="nav__icon"><a href="mailto:info@secondjoke.com"><img src={contact} alt="contact icon"/></a></li>
                <li className="nav__icon"><a href="https://www.instagram.com/secondjokesolutions/"><img src={instagram} alt="instagram icon"></img></a></li>
                <li className="nav__icon"><a href="https://www.linkedin.com/company/second-joke-solutions/"><img src={LinkedIn} alt="linkedin icon"></img></a></li>
            </ul>
            <NavLink to="/"><img src={logobottom} className="footer__logo" alt="logo"></img></NavLink>
            <p className="footer__copyright">© Second Joke Solutions Inc. All Rights Reserved.</p>
        </nav>
    )
}

export default Footer;