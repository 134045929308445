import "../About/About.scss"
import React from "react";
import { Helmet } from "react-helmet";


const About = () => {
    
    return (
        <div className="about-us-container">
        {/* SEO Meta Tags */}
        <Helmet>
          <title>About Us | Second Joke Solutions</title>
          <meta
            name="description"
            content="Discover Second Joke Solutions, a tech company dedicated to creating innovative tools that connect communities and make life easier. Learn more about our founder's vision and our story."
          />
          <meta
            name="keywords"
            content="Second Joke Solutions, innovative tech tools, Salvo app, SnipSupport, creative entrepreneur, community-focused technology"
          />
          <meta name="author" content="[Your Name]" />
        </Helmet>
  
        {/* Header Section */}
        <div className="about-container">
        <header className="about-us-header">
          <h1>About Us</h1>
          <p>
          At Second Joke Solutions, we believe in turning life’s challenges into opportunities for creativity, connection, and community. Our name is a nod to the beauty of perseverance—when the first joke doesn’t land, the second one just might. That spirit of resilience and humor is at the core of everything we do, from the way we build our projects to the way we engage with our users.
          </p>
        </header>
  
        {/* Founder Section */}
        <section className="founder-section">
          <h2>Meet the Founder</h2>
          <p>
            Founded by <strong>Kim Matchett</strong>, Second Joke Solutions began as a spark of creativity born from a deep desire to solve real-world problems with empathy and innovation.

<br></br>
<br></br>
  Whether it’s creating an app that makes expense tracking less of a chore (hello, Salvo!), or developing SnipSupport, a platform that removes financial barriers for pet owners, every project under Second Joke Solutions stems from a desire to create something meaningful and accessible.

But Second Joke isn’t just about solving problems—it’s about building relationships. From empowering small business owners to supporting nonprofits, each of our ventures reflects our commitment to fostering connection and creating technology that brings people together.

          </p>
        </section>
  
        {/* Mission Section */}
        <section className="mission-section">
          <h2>Our Mission</h2>
          <p>
            At Second Joke Solutions, we create tools that make life easier,
            better, and a bit more joyful. From Salvo, an app that simplifies
            expense tracking, to SnipSupport, a platform supporting pet owners in
            financial need, our projects are designed to empower individuals and
            connect communities.
          </p>
        </section>
  
        {/* Call to Action */}
        <section className="cta-section">
          <h2>Join Us</h2>
          <p>
            Life’s better when you laugh at the first joke and stick around for
            the second. Explore our projects and become part of the Second Joke
            community today.
          </p>
          <a href="/portfolio" className="cta-button">
            Explore Our Products
          </a>
        </section>
        </div>


        <div className="about">
            <h2 className="about__section">Purpose</h2>
            <p className="about__text">Our goal is to develop meaningful applications that enhance people's time and happiness. We are committed to creating innovative software solutions that make everyday tasks easier and more enjoyable.</p>
            <h2 className="about__section">Passion</h2>
            <p className="about__text">We aim to build a company where creativity, responsibility, and integrity are not just ideals but everyday practices. We strive to make data driven decisions to ensure that our applications are unbiased and effective.</p>
        </div>
        </div>
    )
}

export default About;