import "../Card/Card.scss"
import Salvo from "../../Assets/Logos/salvoBlack.PNG"
import Slice from "../../Assets/Logos/Slice Bingo Logo.png"
import Snipsupport from "../../Assets/Logos/snipsupport.png"
import yychoney from "../../Assets/Logos/yychoney.png"

const Cards = () => {
    
    return (
        <div>
        <header className="card__header">
                <h1>Explore Our Products</h1>
                <p>Learn about our innovative applications designed to make life easier, healthier, and more connected.</p>
            </header>
        <div className="card">
            <article className="card__container">
            <img className="card__image" src={Salvo} alt="Salvo Expense Helper Logo: Simplify expense management with receipt summaries and smart suggestions."></img>
            <h2 className="card__title">Salvo Expense Helper</h2>
            <p className="card__text">Salvo Expense Helper is a web application designed for anyone who needs help with expenses. Salvo takes receipt management a step further by providing a summary and suggested expense type built on our founder's experience as a corporate card coordinator.</p>
            {/* <Button type='submit' label='Learn more' style='primary' /> */}
            </article>
            <article className="card__container">
            <img className="card__image" src={Slice} alt="Slice Bingo Logo: A fun game promoting healthy habits through small daily actions."></img>
            <h2 className="card__title">Slice Bingo</h2>
            <p className="card__text">Slice Bingo is a game designed to help individuals recognize the small habits and tasks that make them feel healthy and happy. Slice is an acronym that stands for Soma, Living, Intelligence, Connection, and Eating well.</p>
            {/* <Button type='submit' label='Learn more' style='primary' /> */}
            </article>
            {/* <article className="card__container">
            <img className="card__image" src={Volleycom} alt="Volleycom Logo: Sustainable NFC business cards and accessories for modern networking."></img>
            <h2 className="card__title">Volleycom</h2>
            <p className="card__text">Elevate networking with VolleyCom’s NFC business cards and accessories. Share contact info seamlessly while prioritizing sustainability with eco-friendly and reusable tech.</p>
            {/* <Button type='submit' label='Learn more' style='primary' /> */}
    
            <article className="card__container">
            <img className="card__image" src={Snipsupport} alt="SnipSupport Logo: Financial support for pet care, spaying, and neutering services."></img>
            <h2 className="card__title">SnipSupport</h2>
            <p className="card__text">Connect pet owners, vets, and sponsors with SnipSupport, a platform that removes financial barriers for spaying/neutering and helps families keep pets during tough times.</p>
            {/* <Button type='submit' label='Learn more' style='primary' /> */}
            </article>
            <article className="card__container">
            <img className="card__image" src={yychoney} alt="YYC Honey Finder Logo: Discover local honey and support sustainable beekeeping."></img>
            <h2 className="card__title">YYC Honey</h2>
            <p className="card__text">Support local beekeepers and discover honey near you with YYC Honey Finder. Explore nearby vendors, learn about sustainable honey production, and sweeten your life with every purchase!</p>
            </article>
        </div>
        </div>
    )
}


export default Cards;