import React, { useEffect } from 'react';
import "./UpdateForm.scss";

const UpdateForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.text = `
      (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
      .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
      n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
      (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
      ml('account', '1074517');
    `;
    document.body.appendChild(script);

    // Optional: Cleanup to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once on mount only

  return <div class="ml-embedded" data-form="GqQSBY"></div>;
};


export default UpdateForm;