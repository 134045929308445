import logo from "../../Assets/Logos/Color logo - no background.svg"
import "../Header/Header.scss"
import { NavLink } from "react-router-dom"

const Header = () => {

    return (
        <nav className="container__header">
            <NavLink to="/"><img src={logo} className="header__logo" alt="logo"></img></NavLink>
           <ul className="nav">
            <li className="nav__link-about"> <a href="/about">About Us</a></li>
            <li className="nav__link-portfolio"> <a href="/portfolio">Products</a></li>
            <li className="nav__link-contact"> <a href="/contact">Contact</a></li>
          </ul>
        </nav>
    )
}

export default Header;
